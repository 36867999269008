// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-actions-js": () => import("./../../../src/pages/actions.js" /* webpackChunkName: "component---src-pages-actions-js" */),
  "component---src-pages-editions-js": () => import("./../../../src/pages/editions.js" /* webpackChunkName: "component---src-pages-editions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-action-js": () => import("./../../../src/templates/action.js" /* webpackChunkName: "component---src-templates-action-js" */),
  "component---src-templates-edition-js": () => import("./../../../src/templates/edition.js" /* webpackChunkName: "component---src-templates-edition-js" */)
}

